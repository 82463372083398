<template>
  <div class="time-input-form">
    <div class="heading"><slot></slot></div>
    <div class="body">
      <vue-timepicker
        :value="time"
        input-width="90px"
        @input="inputTime"
        :minute-interval="5"
        :hour-label="'時'"
        :minute-label="'分'"
        class="timepicker"
        :hour-range="startHourRange"
        :minute-range="startMinuteRange"
        :hide-clear-button="true"
        :disabled="disabled"
        data-e2e="vue-timepicker"
      ></vue-timepicker>
      <span>
        <div class="error" v-if="validateFlg">{{ timeRule }}</div>
      </span>
      <span v-if="secondFlg">
        ～
        <vue-timepicker
          :value="time2"
          input-width="90px"
          @input="inputTime2"
          class="timepicker"
          :minute-interval="5"
          :hour-label="'時'"
          :minute-label="'分'"
          :hour-range="endHourRange"
          :minute-range="endMinuteRange"
          :hide-clear-button="true"
          :disabled="disabled"
          data-e2e="vue-timepicker"
        ></vue-timepicker>
      </span>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { timeRule } from '@/utils/validation_rules'

export default {
  name: 'TimeInputForm',

  components: {
    VueTimepicker
  },

  props: {
    secondFlg: { type: Boolean, default: false },
    time: { type: [String, Object], default: '00:00' },
    time2: { type: [String, Object], default: '00:00' },
    validateFlg: { type: Boolean },
    disabled: { type: Boolean }
  },

  data() {
    return {
      timeRule: timeRule
    }
  },

  computed: {
    startHourRange() {
      let startHourRange = [[0, 23]]
      const endHour = this.time2.substring(0, 2)
      if (endHour !== '00') {
        startHourRange[0][1] = endHour
      }
      return startHourRange
    },
    startMinuteRange() {
      let startMinuteRange = [[0, 59]]
      const startHour = this.time.substring(0, 2)
      const endHour = this.time2.substring(0, 2)
      if (startHour === endHour && startHour !== '00') {
        startMinuteRange[0][1] = this.time2.substring(3, 5)
      }
      return startMinuteRange
    },
    endHourRange() {
      return [[this.time.substring(0, 2), 23]]
    },
    endMinuteRange() {
      let endMinuteRange = [[0, 59]]
      const startHour = this.time.substring(0, 2)
      const endHour = this.time2.substring(0, 2)
      if (startHour === endHour) {
        endMinuteRange[0][0] = this.time.substring(3, 5)
      }
      return endMinuteRange
    }
  },

  methods: {
    inputTime(e) {
      this.$emit('input', e)
    },
    inputTime2(e) {
      this.$emit('input2', e)
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin timepicker-style() {
  ::v-deep input {
    height: 33px;
    padding: 6px;
    border: solid 2px #{$light-grey};
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 13px;
    text-align: center;
    position: relative;
    &:disabled {
      background-color: #{$light-grey};
      color: #{$brown-gray};
      opacity: 0.7;
    }
  }
  ::v-deep .dropdown {
    height: auto;
    max-height: 10em;
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 12px;
    border-radius: 3px;
    z-index: 20;
    > .select-list {
      height: auto;
      max-height: 10em;
      > ul .active {
        background: #{$pumpkin};
      }
    }
  }
}

.time-input-form {
  display: flex;
  > .heading {
    font-size: 15px;
    line-height: 33px;
    width: 40%;
  }
  > .body {
    width: 60%;
    > .timepicker {
      @include timepicker-style();
    }
    @include validate-message();
    > span {
      > .timepicker {
        @include timepicker-style();
      }
    }
  }
}
.datetime-picker input[data-v-5afb7150] {
  width: 150px;
  height: 38px;
}
</style>
