<template>
  <div class="base-change-year-month">
    <div class="back" @click="back" v-if="isPast202001">
      <div class="back-arrow" data-test="back-arrow"></div>
    </div>
    <div class="year-month" data-test="year-month">
      {{ dispYearMonthDay }}
    </div>
    <div class="next" @click="next" v-if="isAfterOneYear">
      <div class="next-arrow" data-test="next-arrow"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BaseChangeYearMonth',

  props: {
    yearMonth: { type: Object },
    days: { type: Array }
  },

  computed: {
    isPast202001() {
      return this.yearMonth > moment('2020-02-01 00:00:00') ? true : false
    },
    isAfterOneYear() {
      return this.yearMonth < moment().add(11, 'months')
    },
    dispYearMonthDay() {
      return `${moment(this.days[0], 'YYYYMMDD').format(
        'YYYY年 MM月 DD日'
      )} ~ ${moment(this.days[this.days.length - 1], 'YYYYMMDD').format(
        'YYYY年 MM月 DD日'
      )}`
    }
  },

  methods: {
    back() {
      this.$emit('back')
    },
    next() {
      this.$emit('next')
    }
  }
}
</script>

<style lang="scss">
.base-change-year-month {
  display: flex;
  align-items: center;
  height: 45px;
  > .back,
  .next {
    position: relative;
    width: 30px;
    height: 30px;
    margin-top: 2px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: rgba(180, 180, 180, 0.3);
    }

    > .back-arrow {
      position: absolute;
      top: 9px;
      left: 11px;
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #{$gray};
      border-bottom: 2px solid #{$gray};
      transform: rotate(45deg);
    }
    > .next-arrow {
      position: absolute;
      top: 9px;
      left: 7px;
      display: block;
      width: 10px;
      height: 10px;
      border-right: 2px solid #{$gray};
      border-top: 2px solid #{$gray};
      transform: rotate(45deg);
    }
  }
  > .back {
    margin-right: 3px;
  }
  > .year-month {
    width: 350px;
    font-size: 20px;
    font-weight: bold;
    color: #{$greyish-brown};
    text-align: center;
  }
}
</style>
