<template>
  <div class="shift-detail">
    <div class="table">
      <div class="time-block">
        <div class="row">時間</div>
        <div class="time" data-test="time">{{ shiftTime }}</div>
      </div>
      <div class="type-block">
        <div class="row">シフトタイプ</div>
        <div class="type" data-test="type">
          {{ getMasterDatum('shiftTypes', shift.shiftTypeId).name }}
        </div>
      </div>
      <div class="display-block">
        <div class="row">表示</div>
        <div
          class="display"
          data-test="display"
          :style="shiftBlockStyle(shift)"
        >
          {{ shift.showName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftDetail',

  mixins: [ColorMethods],

  props: {
    shift: {
      id: { type: Number },
      name: { type: String },
      showName: { type: String },
      startTime: { type: String },
      endTime: { type: String },
      workingTime: { type: String },
      shiftTypeId: { type: Number },
      color: { type: String }
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    shiftTime() {
      const isDayOff = this.shift.defaultFlg
      if (isDayOff) return '-'
      const start = this.toColonTime(this.shift.startTime)
      const end = this.toColonTime(this.shift.endTime)
      return `${start} ～ ${end}`
    }
  },

  methods: {
    shiftBlockStyle(shift) {
      return {
        color: this.mixinAdaptTextColor(shift.color),
        backgroundColor: shift.color
      }
    },
    toColonTime(HHmm) {
      return moment(HHmm, 'HHmm').format('H:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin cell {
  line-height: 35px;
  border: 1px solid #{$light-grey};
}
@mixin header-cell {
  font-size: 15px;
  @include cell;
}
@mixin body-cell {
  font-size: 13px;
  @include cell;
}
.shift-detail {
  height: 71px;
  display: flex;
  > .table {
    display: flex;
    text-align: center;
    > .time-block {
      width: 159px;
      > .row {
        @include header-cell;
      }
      > .time {
        @include body-cell;
        border-top: 0;
      }
    }
    > .type-block {
      width: 159px;
      > .row {
        @include header-cell;
        border-left: 0;
      }
      > .type {
        @include body-cell;
        border-top: 0;
        border-left: 0;
      }
    }
    > .display-block {
      width: 47px;
      > .row {
        @include header-cell;
        border-left: 0;
      }
      > .display {
        @include body-cell;
        border-top: 0;
        border-left: 0;
      }
    }
  }
}
</style>
