<template>
  <div class="shift-new">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">シフト登録</div>
      <div class="setting-content">
        <div class="input-wrap">
          <div class="heading">基本項目</div>
          <shift-input-form
            class="input-form"
            :shift="shift"
            :validateFlg="validateFlg"
            @input-start-time="inputStartTime"
            @input-end-time="inputEndTime"
          />
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="backShifts"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || validateFlg || waitFlg"
            @click="createShift"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closePopup"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ShiftInputForm from '@/components/parts/organisms/ShiftInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftNew',

  components: {
    ShiftInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      shift: {
        name: '',
        showName: '',
        startTime: '00:00',
        endTime: '00:00',
        workingTime: '00:00',
        shiftTypeId: 4,
        color: '#ffffff'
      },
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      validateFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  watch: {
    shift: {
      handler: function() {
        const startTime = moment(this.shift.startTime, 'HHmm')
        const endTime = moment(this.shift.endTime, 'HHmm')
        const diff = endTime.diff(startTime, 'minutes')
        this.validateFlg = diff < 0
      },
      deep: true
    }
  },

  created() {
    this.mixinInputData = this.shift
    this.mixinSetInitialData()
  },

  methods: {
    inputStartTime(val) {
      this.shift.startTime = val
    },
    inputEndTime(val) {
      this.shift.endTime = val
    },
    backShifts() {
      this.$router.push({ path: '/settings/shifts/shifts' })
    },
    closePopup() {
      this.alertFlg = false
      if (this.title === '完了') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: '/settings/shifts/shifts' })
      }
    },
    async createShift() {
      this.waitFlg = true
      if (this.shift.workingTime === '00:00') {
        this.shift.shiftTypeId = 4
      }
      const result = await this.$store.dispatch(
        'shifts/createShift',
        this.shift
      )
      if (result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      }
      if (result === false) {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage = '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-new {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      > .input-wrap {
        margin-top: 20px;
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .input-form {
          margin-top: 26px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
