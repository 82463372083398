<template>
  <div class="shift-table-row">
    <div class="staff">
      <div
        class="staff-name"
        data-test="staff-name"
        :title="staff | staffNameFormatter"
        @click="openShowPage"
      >
        {{ staff | staffNameFormatter }}
      </div>
      <div class="job-name" data-test="job-name">
        {{ getMasterDatum('jobs', staff.jobId).name }}
      </div>
    </div>
    <div class="week-block" data-test="week-block">
      {{ staff | shiftPatternFormatter }}
    </div>
    <div
      class="date-block"
      v-for="(dayAndShiftId, index) in dayAndShiftIds"
      :key="index"
      :title="dialogText(dayAndShiftId.day)"
      @click="changeShiftId(dayAndShiftId.day)"
      data-test="date-block"
      :class="{ past: isPast(dayAndShiftId.day) }"
      :style="
        !isPast(dayAndShiftId.day)
          ? shiftBlockStyle(getShift(dayAndShiftId.shiftId))
          : ''
      "
    >
      {{
        getShift(dayAndShiftId.shiftId) === undefined
          ? '休'
          : getShift(dayAndShiftId.shiftId).showName
      }}
    </div>
    <div class="total-block">
      <div class="working-day" data-test="working-day">
        {{ workingTimes | workingDayFormatter }}
      </div>
      <div class="holiday" data-test="holiday" data-e2e="holiday">
        {{ workingTimes | holidayFormatter }}
      </div>
      <div class="total" data-test="total">
        {{ workingTimes | totalWorkingTimeFormatter }}
      </div>
    </div>
  </div>
</template>

<script>
import ColorMethods from '@/components/mixins/ColorMethods'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ShiftTableRow',

  filters: {
    staffNameFormatter: staff => {
      return `${staff.lastName} ${staff.firstName}`
    },
    shiftPatternFormatter: staff => {
      return staff.shiftPatternId === 0 ? '×' : '〇'
    },
    workingDayFormatter: workingTimes => {
      let workingDateCount = 0
      workingTimes.forEach(v => {
        if (v !== '0000') workingDateCount++
      })
      return workingDateCount
    },
    holidayFormatter: workingTimes => {
      let holidayCount = 0
      workingTimes.forEach(v => {
        if (v === '0000') holidayCount++
      })
      return holidayCount
    },
    totalWorkingTimeFormatter: workingTimes => {
      let totalWorkingTime = 0
      workingTimes.forEach(v => {
        totalWorkingTime += v.substr(0, 2) * 3600 + v.substr(2, 2) * 60
      })
      const hh = parseInt(totalWorkingTime / 3600)
      const mm = ('00' + parseInt((totalWorkingTime % 3600) / 60)).slice(-2)
      return `${hh}:${mm}`
    }
  },

  mixins: [ColorMethods],

  props: {
    yearMonth: { type: Object },
    staff: {
      id: { type: Number },
      lastName: { type: String },
      firstName: { type: String },
      jobId: { type: Number },
      shiftPatternId: { type: Number }
    },
    staffWorkingDates: {
      staffId: { type: Number },
      yearMonth: { type: String },
      shiftIds: { type: Array }
    },
    days: { type: Array },
    deadline: { type: Number, default: 31 }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById',
      getShift: 'shifts/getDataById'
    }),
    dayAndShiftIds() {
      const yearMonth = this.yearMonth
      const deadline = this.deadline
      const selectYearMonth = moment(yearMonth).format('YYYYMM')
      const currentMonth = this.staffWorkingDates.find(
        v => v.yearMonth === selectYearMonth && v.staffId === this.staff.id
      )
      const currentMonthShiftIds = currentMonth.shiftIds.slice(0, deadline)

      const preYearMonth = moment(yearMonth)
        .subtract(1, 'M')
        .format('YYYYMM')
      const preMonth = this.staffWorkingDates.find(
        v => v.yearMonth === preYearMonth && v.staffId === this.staff.id
      )
      const preMonthShiftIds = preMonth
        ? preMonth.shiftIds.slice(deadline)
        : new Array(
            moment(yearMonth)
              .subtract(1, 'M')
              .daysInMonth() - deadline
          ).fill(0)
      const shiftIds = preMonthShiftIds.concat(currentMonthShiftIds)
      const dayAndShiftIds = shiftIds.map((v, i) => {
        return { shiftId: v, day: this.days[i] }
      })
      return dayAndShiftIds
    },
    workingTimes() {
      return this.dayAndShiftIds.map(v => {
        const existShift = this.getShift(parseInt(v.shiftId))
        return existShift === undefined ? '0000' : existShift.workingTime
      })
    }
  },

  methods: {
    changeShiftId(day) {
      if (moment().add(-1, 'days') < moment(day, 'YYYYMMDD')) {
        const targetShift = {
          staffId: this.staff.id,
          yearMonth: day.substring(0, 6),
          day: Number(day.substring(6, 8))
        }
        this.$emit('click', targetShift)
      }
    },
    isPast(day) {
      return moment().subtract(1, 'd') > moment(day, 'YYYYMMDD') ? true : false
    },
    openShowPage() {
      this.$router.push({ path: `/settings/staffs/${this.staff.id}` })
    },
    dialogText(day) {
      return this.isPast(day) ? '過去分' : ''
    },
    shiftBlockStyle(shift) {
      return {
        color: this.mixinAdaptTextColor(shift.color),
        backgroundColor: shift.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-table-row {
  display: flex;
  height: 57px;
  text-align: center;
  > .staff {
    width: 228px;
    border: solid 1px #{$light-grey};
    border-top: 0;
    > .staff-name {
      line-height: 29px;
      font-size: 17px;
      color: #{$pumpkin};
      text-decoration: underline;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 5px;
      @include hover();
    }
    > .job-name {
      line-height: 28px;
      font-size: 13px;
    }
  }
  > .week-block {
    width: 26px;
    line-height: 57px;
    border-bottom: solid 1px #{$light-grey};
  }
  > .date-block {
    width: 35px;
    line-height: 57px;
    border: solid 1px #{$light-grey};
    border-right: 0;
    border-top: 0;
    &:hover:not(.past) {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0 0 12px;
    }
  }
  > .past {
    background: #{$very-light-grey};
    &:hover {
      cursor: default;
    }
  }
  > .total-block {
    width: 185px;
    line-height: 57px;
    border: solid 1px #{$light-grey};
    border-top: 0;
    display: flex;
    .working-day {
      width: 38px;
    }
    .holiday {
      width: 38px;
      border: solid 1px #{$light-grey};
      border-top: 0;
      border-bottom: 0;
    }
    > .total {
      width: 109px;
    }
  }
}
</style>
