<template>
  <div class="shift-pattern-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">週シフト編集</div>
      <div class="setting-content">
        <div class="input-wrap">
          <div class="heading">基本項目</div>
          <shift-pattern-input-form
            class="input-form"
            :shiftPattern="shiftPattern"
            @input-shift-pattern-name="inputShiftPatternName"
            @input-sunday-shift-id="inputSundayShiftId"
            @input-monday-shift-id="inputMondayShiftId"
            @input-tuesday-shift-id="inputTuesdayShiftId"
            @input-wednesday-shift-id="inputWednesdayShiftId"
            @input-thursday-shift-id="inputThursdayShiftId"
            @input-friday-shift-id="inputFridayShiftId"
            @input-saturday-shift-id="inputSaturdayShiftId"
            @input-holiday-shift-id="inputHolidayShiftId"
          />
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="backShiftPatternShow"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateShiftPattern"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closePopup"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ShiftPatternInputForm from '@/components/parts/organisms/ShiftPatternInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftPatternEdit',

  components: {
    ShiftPatternInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      shiftPattern: {},
      alertFlg: false,
      waitFlg: false,
      noDataFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.shiftPattern = _.cloneDeep(
      this.$store.getters['shiftPatterns/getDataById'](this.id)
    )
    this.mixinInputData = this.shiftPattern
    this.mixinSetInitialData()
  },

  methods: {
    inputShiftPatternName(val) {
      this.shiftPattern.name = val
    },
    inputSundayShiftId(val) {
      this.shiftPattern.sundayShiftId = val
    },
    inputMondayShiftId(val) {
      this.shiftPattern.mondayShiftId = val
    },
    inputTuesdayShiftId(val) {
      this.shiftPattern.tuesdayShiftId = val
    },
    inputWednesdayShiftId(val) {
      this.shiftPattern.wednesdayShiftId = val
    },
    inputThursdayShiftId(val) {
      this.shiftPattern.thursdayShiftId = val
    },
    inputFridayShiftId(val) {
      this.shiftPattern.fridayShiftId = val
    },
    inputSaturdayShiftId(val) {
      this.shiftPattern.saturdayShiftId = val
    },
    inputHolidayShiftId(val) {
      this.shiftPattern.holidayShiftId = val
    },
    backShiftPatternShow() {
      this.$router.push({ path: `/settings/shifts/shift-patterns/${this.id}` })
    },
    closePopup() {
      this.alertFlg = false
      if (this.title === '完了') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({
          path: `/settings/shifts/shift-patterns/${this.id}`
        })
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: '/settings/shifts/shift-patterns' })
      }
    },
    async updateShiftPattern() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'shiftPatterns/updateShiftPattern',
        this.shiftPattern
      )
      if (result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.buttons = ['閉じる']
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'no shiftPattern' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の週シフトは既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'no shift') {
          this.shiftPattern = _.cloneDeep({
            ...this.mixinInitialData,
            name: this.shiftPattern.name
          })
          this.mixinInputData = this.shiftPattern
          this.popupMessage = `既に削除されたシフトデータが使用されています\n最新データに更新しますので再編集をお願いします`
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-pattern-edit {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      > .input-wrap {
        margin-top: 20px;
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .input-form {
          margin-top: 26px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
