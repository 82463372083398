<template>
  <div class="base-button-shift-print">
    <img
      src="@/assets/images/shift_list_print.png"
      :class="{ button: true, 'pointer-events': disabled }"
      @click="handleClick"
      width="137px"
      height="33px"
      alt="shift-list-print"
    />
    <div class="message" v-if="disabled">
      シフト表登録完了後、印刷可能になります
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonShiftPrint',

  props: {
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-shift-print {
  cursor: pointer;
  position: relative;
  > .button {
    height: 33px;
    width: 137px;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin-top: 2px;
      height: 31px;
    }
  }
  > .pointer-events {
    pointer-events: none;
    opacity: 0.4;
  }
  > .message {
    position: absolute;
    font-size: 10px;
  }
}
</style>
