<template>
  <div class="shift-table-wrapper">
    <div class="button-wrapper">
      <base-change-year-month
        class="year-month"
        :yearMonth="yearMonth"
        :days="days"
        @back="backMonth"
        @next="nextMonth"
      />
      <base-button-shift-print
        class="print"
        @click="mixinOpenPdf"
        :disabled="diffFlg"
      />
    </div>
    <shift-table
      class="shift-table"
      :yearMonth="yearMonth"
      :staffWorkingDates="staffWorkingDates"
      :days="days"
      :deadline="deadline"
      @click="changeShiftId"
    />
    <shift-worker-count
      class="shift-worker-count"
      :yearMonth="yearMonth"
      :staffWorkingDates="staffWorkingDates"
      :deadline="deadline"
    />
  </div>
</template>

<script>
import BaseChangeYearMonth from '@/components/parts/atoms/BaseChangeYearMonth'
import BaseButtonShiftPrint from '@/components/parts/atoms/BaseButtonShiftPrint'
import ShiftTable from '@/components/parts/organisms/ShiftTable'
import ShiftWorkerCount from '@/components/parts/organisms/ShiftWorkerCount'
import PrintShift from '@/components/mixins/PrintShift'

export default {
  name: 'ShiftTableWrapper',

  components: {
    BaseChangeYearMonth,
    BaseButtonShiftPrint,
    ShiftTable,
    ShiftWorkerCount
  },

  mixins: [PrintShift],

  props: {
    staffWorkingDates: { type: Array },
    yearMonth: { type: Object },
    days: { type: Array },
    deadline: { type: Number },
    diffFlg: { type: Boolean, default: false }
  },

  methods: {
    backMonth() {
      this.$emit('back')
    },
    nextMonth() {
      this.$emit('next')
    },
    changeShiftId(targetShift) {
      this.$emit('click', targetShift)
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-table-wrapper {
  > .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .print {
      height: 33px;
    }
  }
  > .shift-table {
    margin: 30px 0 20px 0;
  }
}
</style>
