<template>
  <div class="shift-worker-count">
    <div class="title">
      <div class="aaa">日別従事者数</div>
      <div class="am-pm">
        <div class="am">午前</div>
        <div class="pm">午後</div>
      </div>
    </div>
    <div class="body" v-for="(tmp, index) in shiftTypeIds[0]" :key="index">
      <div class="am-body" data-test="am-body">
        {{ shiftTypeIds | amWorkerCountFormatter(index) }}
      </div>
      <div class="pm-body" data-test="pm-body">
        {{ shiftTypeIds | pmWorkerCountFormatter(index) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ShiftWorkerCount',

  filters: {
    amWorkerCountFormatter: (shiftTypeIds, index) => {
      let amWorkerCount = 0
      shiftTypeIds.forEach(v => {
        if (v[index] === 1 || v[index] === 3) amWorkerCount++
      })
      return amWorkerCount
    },
    pmWorkerCountFormatter: (shiftTypeIds, index) => {
      let pmWorkerCount = 0
      shiftTypeIds.forEach(v => {
        if (v[index] === 2 || v[index] === 3) pmWorkerCount++
      })
      return pmWorkerCount
    }
  },

  props: {
    yearMonth: { type: Object },
    staffWorkingDates: { type: Array },
    deadline: { type: Number }
  },

  computed: {
    ...mapGetters({
      getShift: 'shifts/getDataById'
    }),
    selectMonthShiftIds() {
      const yearMonth = this.yearMonth
      const deadline = this.deadline
      const currentMonthStaffWorkingDates = this.staffWorkingDates.filter(
        v => v.yearMonth == moment(yearMonth).format('YYYYMM')
      )
      const currentMonthShiftIds = currentMonthStaffWorkingDates.map(v => {
        return v.shiftIds.slice(0, deadline)
      })
      const preYearMonth = moment(yearMonth)
        .subtract(1, 'M')
        .format('YYYYMM')
      const preMonthStaffWorkingDates = this.staffWorkingDates.filter(
        v => v.yearMonth === preYearMonth
      )
      const preMonthShiftIds = preMonthStaffWorkingDates.map(v => {
        return v.shiftIds.slice(deadline)
      })
      return preMonthShiftIds.map((v, i) => {
        return preMonthShiftIds[i].concat(currentMonthShiftIds[i])
      })
    },
    shiftTypeIds() {
      return this.selectMonthShiftIds.map(shiftIds => {
        return shiftIds.map(v => {
          const notExistShiftTypeId = 4
          const existShift = this.getShift(parseInt(v))
          return existShift === undefined
            ? notExistShiftTypeId
            : existShift.shiftTypeId
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-worker-count {
  display: flex;
  text-align: center;
  > .title {
    width: 255px;
    height: 71px;
    border: solid 1px #{$light-grey};
    background: #{$light-orange};
    display: flex;
    > .aaa {
      width: 75%;
      line-height: 71px;
      font-size: 15px;
    }
    > .am-pm {
      width: 25%;
      > .am,
      .pm {
        line-height: 35px;
        font-size: 12px;
      }
    }
  }
  > .body {
    width: 36px;
    font-size: 15px;
    > .am-body {
      height: 35px;
      line-height: 35px;
      border: solid 1px #{$light-grey};
      border-left: 0;
    }
    > .pm-body {
      height: 35px;
      line-height: 35px;
      border: solid 1px #{$light-grey};
      border-left: 0;
      border-top: 0;
    }
  }
}
</style>
