<template>
  <div class="shift-show">
    <base-back-link class="base-back-link" @click="backShifts" />
    <div class="setting-title">シフト詳細</div>
    <div class="button-wrapper">
      <div class="buttons">
        <base-button-small-white
          class="button"
          @click="pushToShiftEdit"
          v-if="lookOnlyFlg === 0"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          @click="alertOpen"
          v-if="defaultFlg === 0 && lookOnlyFlg === 0"
          >削除</base-button-small-red
        >
      </div>
      <div class="note">
        <span class="text" v-if="defaultFlg === 1"
          >※シフト名「休暇」は削除できません。</span
        >
      </div>
    </div>
    <div class="content">
      <div class="heading">基本項目</div>
      <div class="show-data-list">
        <show-data-list
          :displayKeys="displayKeys"
          :keys="keys"
          :data="displayShift"
        />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteShift"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { timeFormatter } from '@/utils/convert_time'

export default {
  name: 'ShiftShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: [
        'シフト名',
        '表示名',
        '時間',
        '実働時間',
        'シフトタイプ',
        'カラー'
      ],
      keys: ['name', 'showName', 'time', 'workingTime', 'shiftType', 'color'],
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    shift() {
      return this.$store.getters['shifts/getDataById'](this.id)
    },
    defaultFlg() {
      return this.shift?.defaultFlg
    },
    displayShift() {
      const shift = this.shift
      if (shift) {
        const workingTime = shift.workingTime
        return {
          name: shift.name,
          showName: shift.showName,
          time: timeFormatter(shift.startTime + shift.endTime),
          workingTime: `${workingTime.slice(0, 2)}:${workingTime.slice(2, 4)}`,
          shiftType: this.getMasterDatum('shiftTypes', shift.shiftTypeId).name,
          color: shift.color
        }
      } else {
        return {}
      }
    }
  },

  created() {
    this.initializeAlert()
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    backShifts() {
      this.$router.push({ path: '/settings/shifts/shifts' })
    },
    pushToShiftEdit() {
      this.$router.push({ path: `/settings/shifts/shifts/${this.id}/edit` })
    },
    alertOpen() {
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.alertFlg = false
      this.$router.push({ path: '/settings/shifts/shifts' })
    },
    async deleteShift() {
      this.waitFlg = true
      const result = await this.$store.dispatch('shifts/deleteShift', this.id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'using shiftPatterns') {
          this.popupMessage = '週シフトで使用されている為、削除に失敗しました'
        } else if (result === 'using staffWorkingDates') {
          this.popupMessage = '勤務表で使用されている為、削除に失敗しました'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-show {
  margin-bottom: 24px;
  > .base-back-link {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    margin-top: 20px;
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    > .buttons {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .note {
      position: relative;
      display: flex;
      justify-content: flex-end;
      > .text {
        position: absolute;
        z-index: 10;
        margin-top: 17px;
        color: #{$tomato};
        font-size: 13px;
      }
    }
  }
  > .content {
    margin-top: 16px;
    > .heading {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .show-data-list {
      margin-top: 25px;
    }
  }
}
</style>
