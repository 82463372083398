<template>
  <div class="shift-patterns">
    <div class="button-wrap" v-if="!mixinSortModeFlg">
      <base-button-register
        class="button"
        v-if="lookOnlyFlg === 0"
        :text="'週シフト登録'"
        @click="$router.push({ path: 'shift-patterns/new' })"
      />
      <base-sort-button
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="setSortMode"
      />
    </div>
    <div class="button-wrap" v-else>
      <base-button-small-white class="button" @click="cancelSortMode"
        >キャンセル</base-button-small-white
      >
      <base-button-small-orange
        class="button"
        v-if="lookOnlyFlg === 0"
        :disabled="waitFlg"
        @click="updateOrder"
        >並び順登録</base-button-small-orange
      >
    </div>
    <div class="list-wrap">
      <div class="heading">
        <div class="text">
          週シフト一覧<span v-show="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <hint-balloon v-show="!mixinSortModeFlg" :note="hintMsg" />
      </div>
      <list-table
        :headerData="headers"
        :propertyData="properties"
        :bodyData="displayShiftPatterns"
        :sortModeFlg="mixinSortModeFlg"
        @click="openShowPage"
        @order="changeOrder"
      />
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftPatterns',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ListTable,
    HintBalloon,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      headers: [
        '標準',
        'シフト名',
        '日',
        '月',
        '火',
        '水',
        '木',
        '金',
        '土',
        '祝'
      ],
      properties: [
        'order',
        'name',
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'holiday'
      ],
      waitFlg: false,
      hintMsg:
        'スタッフに週シフトを設定すると勤務表画面にてシフトを一括登録すことができます。'
    }
  },

  computed: {
    ...mapGetters({
      shiftPatterns: 'shiftPatterns/getData',
      getShift: 'shifts/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayShiftPatterns() {
      const data = this.mixinSortModeFlg
        ? this.mixinInputData
        : this.shiftPatterns
      return data.map(v => {
        return {
          ...v,
          sunday: this.getShift(v.sundayShiftId)?.name || '',
          monday: this.getShift(v.mondayShiftId)?.name || '',
          tuesday: this.getShift(v.tuesdayShiftId)?.name || '',
          wednesday: this.getShift(v.wednesdayShiftId)?.name || '',
          thursday: this.getShift(v.thursdayShiftId)?.name || '',
          friday: this.getShift(v.fridayShiftId)?.name || '',
          saturday: this.getShift(v.saturdayShiftId)?.name || '',
          holiday: this.getShift(v.holidayShiftId)?.name || ''
        }
      })
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.shiftPatterns
      this.mixinSetInitialData()
    },
    openShowPage(id) {
      this.$router.push({ path: `shift-patterns/${id}` })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('shiftPatterns', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-patterns {
  margin-top: 20px;
  margin-bottom: 24px;
  > .button-wrap {
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > .list-wrap {
    margin-top: 16px;
    ::v-deep .list-table .table-body {
      max-height: 560px;
    }
  }
}
.heading {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  > .text {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
