import ColorMethods from '@/components/mixins/ColorMethods'
import '@/utils/vfs_fonts.js'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import { jpDate } from '@/utils/moment'

export default {
  mixins: [ColorMethods],
  computed: {
    ...mapGetters({
      staffs: 'staffs/getStaffs',
      getShift: 'shifts/getDataById',
      getMasterDatum: 'master/getDataById'
    }),
    mixinDaysRow() {
      const row = [
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] }
      ]
      this.days.forEach(day => {
        row.push({
          text: moment(day, 'YYYYMMDD').format('D'),
          fillColor: '#cecece'
        })
      })
      return row
    },
    mixinWeekdaysRow() {
      const row = [
        { text: 'スタッフ名', fillColor: '#cecece' },
        { text: '職種', fillColor: '#cecece' }
      ]
      this.days.forEach(day => {
        const weekdays = ['日', '月', '火', '水', '木', '金', '土']
        row.push({
          text: `${weekdays[moment(day, 'YYYYMMDD').day()]}`,
          fillColor: '#ddfbff'
        })
      })
      return row
    },
    mixinStaffShiftRows() {
      return this.staffs.map(staff => {
        const staffName = `${staff.lastName} ${staff.firstName}`.substring(
          0,
          13
        )
        const jobName = this.getMasterDatum('jobs', staff.jobId).name
        const shifts = this.days.map(day => {
          const staffWorkingDate = this.staffWorkingDates.find(
            v => v.yearMonth === day.substring(0, 6) && v.staffId === staff.id
          )
          const shiftId =
            staffWorkingDate.shiftIds[Number(day.substring(6)) - 1]
          const shift = this.getShift(shiftId)
          return {
            text: shift ? shift.showName : '休',
            fillColor: shift ? shift.color : '#ffffff',
            color: shift ? this.mixinAdaptTextColor(shift.color) : '#000000'
          }
        })
        return [staffName, jobName].concat(shifts)
      })
    },
    mixinBodyRow() {
      return [this.mixinDaysRow, this.mixinWeekdaysRow].concat(
        this.mixinStaffShiftRows
      )
    },
    mixinDocDefinition() {
      const startDate = jpDate({ YYYYMMDD: this.days[0] })
      const endDate = jpDate({ YYYYMMDD: this.days.at(-1) })
      return {
        content: [
          {
            text: `${startDate} ～ ${endDate}`,
            style: { fontSize: 15, alignment: 'center' }
          },
          {
            table: { body: this.mixinBodyRow },
            style: { fontSize: 10, alignment: 'center' }
          }
        ],
        defaultStyle: { font: 'NotoSansMono' },
        pageSize: 'A4',
        pageMargins: [20, 20, 20, 20],
        pageOrientation: 'landscape'
      }
    }
  },

  methods: {
    mixinOpenPdf() {
      const fonts = {
        NotoSansMono: {
          normal: 'NotoSansMonoCJKJPRegular.otf',
          bold: 'NotoSansMonoCJKJPRegular.otf',
          italics: 'NotoSansMonoCJKJPRegular.otf',
          bolditalics: 'NotoSansMonoCJKJPRegular.otf'
        }
      }
      createPdf(this.mixinDocDefinition, {}, fonts).open()
    }
  }
}
