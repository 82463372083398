<template>
  <div class="shift-pattern-input-form">
    <text-input-form
      class="shift-pattern-name"
      :requiredFlg="true"
      :maxlength="10"
      :placeholder="'10文字まで'"
      :value="shiftPattern.name"
      @input="inputShiftPatternName"
      >週シフト名</text-input-form
    >
    <select-box-form
      class="shift"
      v-for="weekday in weekdays"
      :key="weekday.name"
      :value="weekday.value"
      :selectData="shifts"
      @input="weekday.method"
      >{{ weekday.name }}</select-box-form
    >
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftPatternInputForm',

  components: {
    TextInputForm,
    SelectBoxForm
  },

  props: {
    shiftPattern: {
      name: { type: String },
      sundayShiftId: { type: Number },
      mondayShiftId: { type: Number },
      tuesdayShiftId: { type: Number },
      wednesdayShiftId: { type: Number },
      thursdayShiftId: { type: Number },
      fridayShiftId: { type: Number },
      saturdayShiftId: { type: Number },
      holidayShiftId: { type: Number }
    }
  },

  data() {
    return {
      weekdays: [
        {
          name: '日',
          value: this.shiftPattern.sundayShiftId,
          method: this.inputSundayShiftId
        },
        {
          name: '月',
          value: this.shiftPattern.mondayShiftId,
          method: this.inputMondayShiftId
        },
        {
          name: '火',
          value: this.shiftPattern.tuesdayShiftId,
          method: this.inputTuesdayShiftId
        },
        {
          name: '水',
          value: this.shiftPattern.wednesdayShiftId,
          method: this.inputWednesdayShiftId
        },
        {
          name: '木',
          value: this.shiftPattern.thursdayShiftId,
          method: this.inputThursdayShiftId
        },
        {
          name: '金',
          value: this.shiftPattern.fridayShiftId,
          method: this.inputFridayShiftId
        },
        {
          name: '土',
          value: this.shiftPattern.saturdayShiftId,
          method: this.inputSaturdayShiftId
        },
        {
          name: '祝',
          value: this.shiftPattern.holidayShiftId,
          method: this.inputHolidayShiftId
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      shifts: 'shifts/selectData'
    })
  },

  methods: {
    inputShiftPatternName(val) {
      this.$emit('input-shift-pattern-name', val)
    },
    inputSundayShiftId(val) {
      this.$emit('input-sunday-shift-id', val)
    },
    inputMondayShiftId(val) {
      this.$emit('input-monday-shift-id', val)
    },
    inputTuesdayShiftId(val) {
      this.$emit('input-tuesday-shift-id', val)
    },
    inputWednesdayShiftId(val) {
      this.$emit('input-wednesday-shift-id', val)
    },
    inputThursdayShiftId(val) {
      this.$emit('input-thursday-shift-id', val)
    },
    inputFridayShiftId(val) {
      this.$emit('input-friday-shift-id', val)
    },
    inputSaturdayShiftId(val) {
      this.$emit('input-saturday-shift-id', val)
    },
    inputHolidayShiftId(val) {
      this.$emit('input-holiday-shift-id', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-pattern-input-form {
  width: 600px;
  > .shift-pattern-name {
    margin-bottom: 20px;
  }
  > .shift {
    margin-bottom: 20px;
  }
}
</style>
