<template>
  <div class="shift-pattern-show">
    <base-back-link class="base-back-link" @click="backShiftPatterns" />
    <div class="setting-title">週シフト詳細</div>
    <div class="button-wrapper">
      <base-button-small-white
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="pushToShiftPatternEdit"
        >編集</base-button-small-white
      >
      <base-button-small-red
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="alertOpen"
        >削除</base-button-small-red
      >
    </div>
    <div class="content">
      <div class="heading">基本項目</div>
      <div class="show-data-list">
        <show-table :data="displayShiftPatterns" />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      @cancel="alertClose"
      @decision="deleteShiftPattern"
      @close="closePopup"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowTable from '@/components/parts/molecules/ShowTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ShiftPatternShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowTable,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['削除しない', '削除する'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getShiftPattern: 'shiftPatterns/getDataById',
      getShift: 'shifts/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    shiftPattern() {
      return this.$store.getters['shiftPatterns/getDataById'](this.id)
    },
    displayShiftPatterns() {
      const shiftPattern = this.shiftPattern
      if (shiftPattern) {
        return [
          { key: '週シフト名', value: shiftPattern.name },
          {
            key: '日',
            value: this.getShift(shiftPattern.sundayShiftId)?.name || ''
          },
          {
            key: '月',
            value: this.getShift(shiftPattern.mondayShiftId)?.name || ''
          },
          {
            key: '火',
            value: this.getShift(shiftPattern.tuesdayShiftId)?.name || ''
          },
          {
            key: '水',
            value: this.getShift(shiftPattern.wednesdayShiftId)?.name || ''
          },
          {
            key: '木',
            value: this.getShift(shiftPattern.thursdayShiftId)?.name || ''
          },
          {
            key: '金',
            value: this.getShift(shiftPattern.fridayShiftId)?.name || ''
          },
          {
            key: '土',
            value: this.getShift(shiftPattern.saturdayShiftId)?.name || ''
          },
          {
            key: '祝',
            value: this.getShift(shiftPattern.holidayShiftId)?.name || ''
          }
        ]
      } else {
        return {}
      }
    }
  },

  methods: {
    backShiftPatterns() {
      this.$router.push({ path: '/settings/shifts/shift-patterns' })
    },
    pushToShiftPatternEdit() {
      this.$router.push({
        path: `/settings/shifts/shift-patterns/${this.id}/edit`
      })
    },
    alertOpen() {
      this.alertFlg = true
    },
    alertClose() {
      this.alertFlg = false
    },
    closePopup() {
      this.$router.push({ path: '/settings/shifts/shift-patterns' })
    },
    async deleteShiftPattern() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'shiftPatterns/deleteShiftPattern',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'using data') {
          this.popupMessage = 'スタッフに設定されている為、削除に失敗しました'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-pattern-show {
  margin-bottom: 24px;
  > .base-back-link {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    margin-top: 20px;
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > .content {
    margin-top: 16px;
    > .heading {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .show-data-list {
      margin-top: 15px;
    }
  }
}
</style>
