<template>
  <div class="shift-input-form">
    <text-input-form
      class="row"
      :requiredFlg="true"
      :maxlength="10"
      :placeholder="'10文字まで'"
      :disabled="defaultFlg"
      v-model="shift.name"
      >シフト名</text-input-form
    >
    <text-input-form
      class="row"
      :requiredFlg="true"
      :maxlength="1"
      :placeholder="'1文字まで'"
      :disabled="defaultFlg"
      :styles="{ width: '100px' }"
      v-model="shift.showName"
      >表示名</text-input-form
    >
    <time-input-form
      class="row"
      :time="shift.startTime"
      :time2="shift.endTime"
      :secondFlg="true"
      :validateFlg="validateFlg"
      :disabled="defaultFlg"
      @input="inputStartTime"
      @input2="inputEndTime"
      >時間</time-input-form
    >
    <time-input-form
      class="row"
      :time="shift.workingTime"
      :disabled="defaultFlg"
      v-model="shift.workingTime"
      >実働時間</time-input-form
    >
    <select-box-form
      class="row"
      :selectData="shiftTypes"
      :disabled="defaultFlg"
      v-model.number="shift.shiftTypeId"
      >シフトタイプ</select-box-form
    >
    <color-input-form
      class="row"
      :requiredFlg="true"
      :color="shift.color"
      v-model="shift.color"
      >カラー</color-input-form
    >
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import TimeInputForm from '@/components/parts/molecules/TimeInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import ColorInputForm from '@/components/parts/molecules/ColorInputForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftInputForm',

  components: {
    TextInputForm,
    TimeInputForm,
    SelectBoxForm,
    ColorInputForm
  },

  props: {
    shift: {
      name: { type: String },
      showName: { type: String },
      startTime: { type: String },
      endTime: { type: String },
      workingTime: { type: String },
      shiftTypeId: { type: Number },
      defaultFlg: { type: Number },
      color: { type: String }
    },
    validateFlg: { type: Boolean }
  },

  computed: {
    ...mapGetters({
      shiftTypes: 'master/getShiftTypes'
    }),
    defaultFlg() {
      return this.shift.defaultFlg === 1 ? true : false
    }
  },

  methods: {
    inputStartTime(val) {
      this.$emit('input-start-time', val)
    },
    inputEndTime(val) {
      this.$emit('input-end-time', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-input-form {
  width: 600px;
  > .row ~ .row {
    margin-top: 20px;
  }
}
</style>
