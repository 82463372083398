const timeFormatter = times => {
  if (times instanceof Array) {
    return times
      .map(v => {
        const start = `${v.slice(0, 2)}:${v.slice(2, 4)}`
        const end = `${v.slice(4, 6)}:${v.slice(6)}`
        return `${start}～${end}`
      })
      .join(', ')
  } else {
    const start = `${times.slice(0, 2)}:${times.slice(2, 4)}`
    const end = `${times.slice(4, 6)}:${times.slice(6)}`
    return `${start}～${end}`
  }
}

module.exports = {
  timeFormatter
}
