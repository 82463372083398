<template>
  <div class="shift-table">
    <shift-table-header :days="days" :yearMonth="yearMonth" />
    <shift-table-row
      v-for="staff in staffs"
      :key="staff.id"
      :staff="staff"
      :yearMonth="yearMonth"
      :staffWorkingDates="staffWorkingDates"
      :days="days"
      :deadline="deadline"
      @click="changeShiftId"
    />
  </div>
</template>

<script>
import ShiftTableHeader from '@/components/parts/organisms/ShiftTableHeader'
import ShiftTableRow from '@/components/parts/organisms/ShiftTableRow'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftTable',

  components: {
    ShiftTableHeader,
    ShiftTableRow
  },

  props: {
    yearMonth: { type: Object },
    staffWorkingDates: { type: Array },
    days: { type: Array },
    deadline: { type: Number }
  },

  computed: {
    ...mapGetters({
      staffs: 'staffs/getStaffs'
    })
  },

  methods: {
    changeShiftId(targetShift) {
      this.$emit('click', targetShift)
    }
  }
}
</script>
