<template>
  <div class="shift-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">シフト編集</div>
      <div class="setting-content">
        <div class="input-wrap">
          <div class="heading">
            基本項目
            <div class="note" v-if="shift.defaultFlg === 1">
              ※シフト名「休暇」はカラーのみ編集できます。
            </div>
          </div>
          <shift-input-form
            class="input-form"
            :shift="shift"
            :validateFlg="validateFlg"
            @input-start-time="inputStartTime"
            @input-end-time="inputEndTime"
          />
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="backShiftShow"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || validateFlg || waitFlg"
            @click="updateShift"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closePopup"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ShiftInputForm from '@/components/parts/organisms/ShiftInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftEdit',

  components: {
    ShiftInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    ValidationObserver,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      shift: {},
      alertFlg: false,
      waitFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '編集しました',
      validateFlg: false,
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  watch: {
    shift: {
      handler: function() {
        const startTime = moment(this.shift.startTime, 'HHmm')
        const endTime = moment(this.shift.endTime, 'HHmm')
        const diff = endTime.diff(startTime, 'minutes')
        this.validateFlg = diff < 0
      },
      deep: true
    }
  },

  created() {
    const shift = _.cloneDeep(
      this.$store.getters['shifts/getDataById'](this.id)
    )
    shift.startTime = this.insertColon(shift.startTime)
    shift.endTime = this.insertColon(shift.endTime)
    shift.workingTime = this.insertColon(shift.workingTime)
    this.shift = shift
    this.mixinInputData = this.shift
    this.mixinSetInitialData()
  },

  methods: {
    insertColon(str) {
      return str.slice(0, 2) + ':' + str.slice(2, 4)
    },
    inputStartTime(val) {
      this.shift.startTime = val
    },
    inputEndTime(val) {
      this.shift.endTime = val
    },
    backShiftShow() {
      this.$router.push({ path: `/settings/shifts/shifts/${this.id}` })
    },
    closePopup() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/settings/shifts/shifts/${this.id}` })
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/settings/shifts/shifts` })
      }
    },
    async updateShift() {
      this.waitFlg = true
      if (this.shift.workingTime === '00:00') {
        this.shift.shiftTypeId = 4
      }
      const result = await this.$store.dispatch(
        'shifts/updateShift',
        this.shift
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中のシフトは既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-edit {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      > .input-wrap {
        margin-top: 20px;
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          > .note {
            margin-top: 17px;
            font-size: 13px;
            font-weight: normal;
            color: #{$tomato};
          }
        }
        > .input-form {
          margin-top: 26px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
