<template>
  <div class="shift-select-wrapper">
    <div class="radio-buttons list">
      <base-radio-button
        v-for="datum in radioButtonData"
        :key="datum.id"
        :option="datum"
        :value="shiftId"
        :styles="{ height: '71px', fontSize: '15px' }"
        :inputStyles="{ paddingLeft: '20px' }"
        @input="selectShift"
      />
    </div>
    <div class="shifts list">
      <shift-detail v-for="shift in shifts" :key="shift.id" :shift="shift" />
    </div>
    <div class="buttons">
      <base-button-small-orange :disabled="isPast" @click="bulkRegistration"
        >一括登録</base-button-small-orange
      >
      <base-button-small-red @click="bulkClear" :disabled="isPast"
        >一括削除</base-button-small-red
      >
    </div>
  </div>
</template>

<script>
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import ShiftDetail from '@/components/parts/organisms/ShiftDetail'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'ShiftSelectWrapper',

  components: {
    BaseRadioButton,
    ShiftDetail,
    BaseButtonSmallOrange,
    BaseButtonSmallRed
  },

  props: {
    shiftId: { type: Number },
    yearMonth: { type: Object }
  },

  computed: {
    shifts() {
      return _.cloneDeep(this.$store.getters['shifts/getData'])
    },
    radioButtonData() {
      return this.shifts.map((v, i) => {
        return {
          id: i + 1,
          eachValue: v.id,
          labelName: v.name
        }
      })
    },
    isPast() {
      return this.yearMonth >= moment().subtract(1, 'months') ? false : true
    }
  },

  methods: {
    selectShift(shiftId) {
      this.$emit('input', shiftId)
    },
    bulkRegistration() {
      this.$emit('bulk-registration')
    },
    bulkClear() {
      this.$emit('bulk-clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-select-wrapper {
  display: flex;
  gap: 0 30px;
  > .list {
    display: flex;
    flex-direction: column;
    gap: 30px 0;
  }
  > .buttons {
    display: flex;
    gap: 0 20px;
  }
}
</style>
