<template>
  <div class="shift-table-header">
    <div class="space-block">
      <div class="print-year">{{ yearMonth | yearMonthFormatter }}</div>
    </div>
    <div class="week-block">週</div>
    <div
      class="date-block"
      v-for="day in days"
      :key="day"
      :style="dateBlockColor(day)"
      data-test="date-block"
    >
      <div class="date" data-test="date">{{ day | dayFormatter }}</div>
      <div class="weekday" data-test="weekday">
        {{ day | dayOfTheWeekFormatter }}
      </div>
    </div>
    <div class="total-block">
      <div class="top">集計</div>
      <div class="bottom">
        <div class="working">勤務</div>
        <div class="holiday">休み</div>
        <div class="total">合計</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftTableHeader',

  filters: {
    yearMonthFormatter: yearMonth => {
      return moment(yearMonth).format('YYYY年 M月')
    },
    dayFormatter: day => {
      return moment(day).format('M/D')
    },
    dayOfTheWeekFormatter: day => {
      const dayOfTheWeek = ['日', '月', '火', '水', '木', '金', '土']
      return dayOfTheWeek[moment(day).get('day')]
    }
  },

  props: {
    days: { type: Array },
    yearMonth: { type: Object }
  },

  computed: {
    ...mapGetters({
      publicHolidays: 'master/getPublicHolidays'
    }),
    dateBlockColor: function() {
      return function(day) {
        let color
        if (this.publicHolidays.indexOf(day) >= 0) {
          color = 'background: #eaddff;'
        } else if (moment(day).get('day') === 6) {
          color = 'background: #ddfbff;'
        } else if (moment(day).get('day') === 0) {
          color = 'background: #ffdddd;'
        } else {
          color = 'background: white;'
        }
        return color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shift-table-header {
  display: flex;
  height: 57px;
  font-size: 15px;
  text-align: center;
  > .space-block {
    width: 229px;
    border: solid 1px #{$light-grey};
    border-top: 0;
    border-left: 0;
    > .print-year {
      display: none;
    }
  }
  > .week-block {
    width: 26px;
    line-height: 57px;
    border: solid 1px #{$light-grey};
    border-right: 0;
    border-left: 0;
  }
  > .date-block {
    width: 35px;
    border: solid 1px #{$light-grey};
    border-right: 0;
    font-size: 12px;
    > .date {
      margin-top: 10px;
    }
  }
  > .total-block {
    width: 185px;
    background: #{$light-orange};
    border: solid 1px #{$light-grey};
    > .top {
      height: 29px;
      line-height: 29px;
    }
    > .bottom {
      height: 28px;
      line-height: 28px;
      display: flex;
      > .working,
      .holiday {
        width: 38px;
      }
      > .total {
        width: 109px;
      }
    }
  }
}
</style>
