<template>
  <div class="shifts">
    <div class="button-wrap" v-if="!mixinSortModeFlg">
      <base-button-register
        class="button"
        v-if="lookOnlyFlg === 0"
        :text="'シフト登録'"
        @click="$router.push({ path: 'shifts/new' })"
      />
      <base-sort-button
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="setSortMode"
      />
    </div>
    <div class="button-wrap" v-else>
      <base-button-small-white class="button" @click="cancelSortMode"
        >キャンセル</base-button-small-white
      >
      <base-button-small-orange
        class="button"
        v-if="lookOnlyFlg === 0"
        :disabled="waitFlg"
        @click="updateOrder"
        >並び順登録</base-button-small-orange
      >
    </div>
    <div class="list-wrap">
      <div class="heading">
        シフト一覧<span v-if="mixinSortModeFlg">{{
          mixinSortModeMessage
        }}</span>
      </div>
      <list-table
        :headerData="headers"
        :propertyData="properties"
        :bodyData="displayShifts"
        :sortModeFlg="mixinSortModeFlg"
        @click="openShowPage"
        @order="changeOrder"
      />
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'
import { timeFormatter } from '@/utils/convert_time'

export default {
  name: 'Shifts',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      headers: [
        '標準',
        'シフト名',
        '表示名',
        '時間',
        '実働時間',
        'シフトタイプ'
      ],
      properties: [
        'order',
        'name',
        'showName',
        'time',
        'workingTime',
        'shiftTypeId'
      ],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      shifts: 'shifts/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayShifts() {
      const data = this.mixinSortModeFlg ? this.mixinInputData : this.shifts
      return data.map(v => {
        return {
          ...v,
          time: timeFormatter(`${v.startTime}${v.endTime}`),
          workingTime: `${v.workingTime.slice(0, 2)}:${v.workingTime.slice(2)}`
        }
      })
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.shifts
      this.mixinSetInitialData()
    },
    openShowPage(id) {
      this.$router.push({ path: `shifts/${id}` })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('shifts', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.shifts {
  margin-top: 20px;
  margin-bottom: 24px;
  > .button-wrap {
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > .list-wrap {
    margin-top: 16px;
    ::v-deep .list-table .table-body {
      max-height: 560px;
    }
  }
}
.heading {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: bold;
}
</style>
